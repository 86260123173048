<!--v-bind:style="{ backgroundColor: row['color']}"-->
<template>
  <tr
      class="cells"
      v-show="row['show']"

      @click="handler"
      :id="row['id']"
      v-for="row in data"
  >
    <td class="cell first_cell">
      <div class="cell_div">
      {{ row["name"] }}
      </div>
    </td>
    <td class="cell" v-for="d in days">
      <div class="cell_div">
        <a v-if="row.link !== undefined" :href="row.link">
          {{ row[d] }}
        </a>
        <div v-else>
          {{ row[d] }}
        </div>
      </div>

    </td>
  </tr>
</template>

<script>
import {useState} from '../store';
import {computed} from "vue";
import {getDays} from "@/components/TableHead.vue";

export default {

  setup() {
    let state = useState();

    const data = computed(() => state.data)

    const days = computed(() => {
      console.log("days")
      let state = useState();
      console.log(getDays(state.date))
      return getDays(state.date)
    })

    const handler = (event) => {
      let e = event.target.parentElement
      let p = event.target.parentElement.parentElement
      let tagId

      if (e.id !== '') {
        tagId = e.id
      } else if (p.id !== '') {
        tagId = p.id
      }

      for (let index of state.data[tagId].hierarchy) {

        for (let i of state.data[index].hierarchy) {
          if (state.data[index]['show'] && state.data[i]['show']) {
            state.data[i]['show'] = false
          }
        }

        if (state.data[index]['show']) {
          state.data[index]['show'] = false
        } else {
          state.data[index]['show'] = true
        }

      }
      // console.log(el);
      // console.log(el.children);
    }

    return {
      state,
      data,
      days,
      handler
    }
  }
}
;
</script>

<style>
.cell {
  display: inline-block;
  text-align: center;

  width: 100px;
  max-width: 300px;
  border: 1px solid black;
  height: 50px;
}

.first_cell {
  width: 300px;
  max-width: 500px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  position: sticky;
  left: 0;
  background: white;
}

.cells {
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cells:hover {
  background-color: lavenderblush;
}

.cell_div {
  display: inline-block;
  margin-top: 10px;
}

</style>