<template>
  <RootTable />
</template>

<script>
import RootTable from './components/RootTable.vue'

export default {
  name: 'App',
  components: {
    RootTable
  }
}
</script>
