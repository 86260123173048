import {reactive, provide, inject} from 'vue';

export const stateSymbol = Symbol('state');
export const createState = () => reactive(
  {
    data: [],
    date: {
      month: new Date().getMonth(),
      year: new Date().getFullYear()
    },
    minDate: new Date("2023-04-01T00:00:00")
  }
);

export const useState = () => inject(stateSymbol);
export const provideState = () => provide(
  stateSymbol,
  createState()
);