<template>
  <table>
    <TableHead />
    <TableRow />
  </table>
</template>

<script>
import TableHead from "@/components/TableHead";
import TableRow from "@/components/TableRow";


export default {
  name: 'RootTable',
  components: {
    TableHead,
    TableRow
  }
}
</script>