<template>
  <thead>
    <tr class="row">
      <th class="column first_column">
        <VueDatePicker
            v-model="state.date"
            month-picker
            locale="ru"
            :min-date="state.minDate"
            @update:model-value="updateData"
        />
      </th>
      <th class="column" v-for="item in days()">
        {{ item }}
      </th>
    </tr>
  </thead>
</template>


<script>
import {useState} from '../store';
import axios from "axios";
import {computed} from "vue";

export let getDays = (date) => {
  let dayInMonth = new Date(date.year, date.month + 1, 0).getDate();
  return Array.from({length: dayInMonth}, (x, i) => i + 1).concat(["Предыдущий", "Итоги"]);
}

export default {
  setup() {
    const state = useState()

    const updateData = computed(async () => {
      axios.get('https://report.k8s.lonsdaleites.ru/api/general/' + state.date.year + "/" + (state.date.month + 1)).then((r) => {
        if (r.status === 200) {
          let _rows = r.data
          let days = getDays(state.date)

          let rows = {}
          let row = {}

          let fistLevelIndex = null
          let secondLevelIndex = null

          for (let i = 0; i < _rows.length; i++) {
            let key = Object.keys(_rows[i])[0]
            let rowData = _rows[i][key]

            if (!key.includes("|")) {
              if (fistLevelIndex !== null) {
                fistLevelIndex = null
              }

              row = {
                ...{
                  "id": i + 1,
                  "name": key,
                  "hierarchy": [],
                  "level": 1,
                  "show": true,
                  "link": undefined,
                },
                ...rowData
              }
              rows[i + 1] = row;
              continue
            }

            if (key.includes("|")) {
              if ((key.split( " | ").length - 1) === 1) {

                if (fistLevelIndex === null) {
                  fistLevelIndex = i
                }

                if (secondLevelIndex !== null) {
                  secondLevelIndex = null
                }

                row = {
                  ...{
                    "id": i + 1,
                    "name": key.split(" | ").slice(1).join(" "),
                    "hierarchy": [],
                    "level": 2,
                    "show": false,
                    "color": "#F0CCDA",
                    "link": undefined,
                  },
                  ...rowData
                }
                rows[fistLevelIndex]["hierarchy"].push(i + 1)
                rows[i + 1] = row;
              } else {

                if (secondLevelIndex === null) {
                  secondLevelIndex = i
                }

                row = {
                  ...{
                    "id": i + 1,
                    "name": key.split(" | ").slice(2).join(" "),
                    "level": 3,
                    "hierarchy": [],
                    "show": false,

                  },
                  ...rowData
                }
                rows[secondLevelIndex]["hierarchy"].push(i + 1)
                rows[i + 1] = row;
              }

            }
          }
          console.log(rows)
          state.data = rows
        }
      }).catch((e) => {console.log(e)})
    })

    const days = () => {
      let state = useState();
      return getDays(state.date)
    }

    return {
      state: state,
      days,
      updateData,
    }
  }
};
</script>

<style>
.column {
  display: inline-block;
  text-align: center;
  width: 100px;
  max-width: 300px;
  height: 50px;
  border: 1px solid black;
}
.first_column {
  width: 300px;
  max-width: 500px;
  max-height: 50px;
}

.row {
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>