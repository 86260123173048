import {createApp} from 'vue'
import App from './App.vue'
import {stateSymbol, createState} from './store';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import "./main.css"

const app = createApp(App)
app.component('VueDatePicker', VueDatePicker);
app.provide(stateSymbol, createState());
app.mount('#app')
